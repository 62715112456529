<template>
  <!-- 盘点详情 -->
  <div class="stockDetails page-info-content">
    <DetailsPage :detail-obj="inventoryDetailObj" :details-item-arr="detailsItemArr" />
    <h3 class="page-subtitle-shade">
      货位明细
    </h3>
    <Table :item-data="itemData" :list-data="inventoryDetailObj.wmsInventoryCheckList" :loading="loading">
      <template #srcInvWeight="{ row }">
        <span>{{ row.srcInvWeight }}{{ row.unit }}</span>
      </template>
      <template #invWeight="{ row }">
        <span>{{ row.invWeight }}{{ row.unit }}</span>
      </template>
      <template #differencesNum="{ row }">
        <span :class="row.differencesNum > 0 ? 'green' : row.differencesNum < 0 ? 'red' : ''">{{ row.differencesNum }}</span>
      </template>
      <template #result="{ row }">
        <span :class="row.differencesNum > 0 ? 'green' : row.differencesNum < 0 ? 'red' : ''">{{ row.result }}</span>
      </template>
    </Table>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage'
import Table from '@/components/Table'
import { subPrecision } from '@/utils/util'
export default {
  components: { DetailsPage, Table },
  data() {
    return {
      loading: false,
      pnid: '',
      inventoryDetailObj: {},
      detailsItemArr: [
        { label: '盘点单号', value: 'checkNo' },
        { label: '仓库名称', value: 'warehouseName' },
        { label: '货主名称', value: 'custName' },
        { label: '盘点日期', value: 'checkDate' },
        { label: '操作人', value: 'operator' }
      ],
      itemData: [
        { label: '品名', prop: 'goodsName' },
        { label: '材质', prop: 'materials' },
        { label: '规格', prop: 'specs' },
        { label: '产地', prop: 'productions' },
        { label: '件重(吨)', prop: 'weights' },
        { label: '库区编号', prop: 'goodsYardNo' },
        { label: '货位号', prop: 'locationNo' },
        { label: '库存数量/单位', prop: 'srcInvWeight' },
        { label: '实盘数量', prop: 'invWeight' },
        { label: '差异数', prop: 'differencesNum' },
        { label: '结果', prop: 'result' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/warehousingSystem/stockManagement', name: '入库管理' }, { path: '', name: '详情' }]
    }
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.inventoryDetailObj = this.$store.state.resourceByTaskObj || JSON.parse(sessionStorage.resourceByTaskObj)
      this.inventoryDetailObj.wmsInventoryCheckList.map((item) => {
        item.differencesNum = (subPrecision(item.invWeight, item.srcInvWeight))
        item.result = item.differencesNum > 0 ? '盘盈' : item.differencesNum < 0 ? '盘亏' : '相符'
      })
    }
  }
}
</script>

<style>
.stockDetails .el-descriptions {
  padding: 40px 32px 0;
}
.cargoDetail {
  padding: 10px 32px!important;
}
.stockDetails .el-descriptions-row td {
  padding-bottom: 28px !important;
}
.stockDetails .tableBox {
  margin: auto;
  text-align: center;
  width: calc(100% - 128px);
  border-collapse: collapse;
}
.stockDetails .tableBox td {
  height: 40px;
  border: 1px solid #ededed;
}
.stockDetails .el-descriptions .el-descriptions__body .link {
  color: rgb(64, 158, 255);
  text-decoration: underline;
  cursor: pointer;
}
.stockDetails .el-descriptions .el-descriptions__body {
  padding: 0 32px;
}
</style>
